'use client';

import * as React from 'react';

import type * as Polymorphic from '@radix-ui/react-polymorphic';
import clsx from 'clsx';

import { Spinner } from '@/components/ui';

const ElementType = 'button';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'minimal'
  | 'minimal-knockout'
  | 'minimal-danger'
  | 'danger'
  | 'ghost'
  | 'dark';

export type ButtonProps = {
  spacing?: 'default' | 'none';
  size?: 'sm' | 'lg';
  variant?: ButtonVariant;
  state?: 'waiting';
};

type PolymorphicButton = Polymorphic.ForwardRefComponent<typeof ElementType, ButtonProps>;

export const Button = React.forwardRef(
  (
    { as: Component = ElementType, variant = 'primary', size = 'lg', state, className, children, ...props },
    forwardedRef,
  ) => (
    <Component
      className={clsx(
        'relative inline-block rounded-full text-sm font-lato outline-none disabled:opacity-50',
        {
          'py-[10px]': size === 'sm' && variant !== 'ghost',
          'py-4': size === 'lg' && variant !== 'ghost',
          'px-4': size === 'sm' && !variant.includes('minimal') && variant !== 'ghost',
          'px-6': size === 'lg' && !variant.includes('minimal') && variant !== 'ghost',
          'text-khaki-1 bg-gradient-purple hover:bg-purple hover:bg-none focus-visible:ring-4 focus-visible:ring-purple/25 active:bg-purple-2 active:bg-none':
            variant === 'primary',
          'border-2 border-purple-3 text-purple-3 hover:bg-purple-3 hover:text-khaki-1 focus-visible:ring-4 focus-visible:ring-purple/25 active:bg-purple-2 active:text-khaki-1':
            variant === 'secondary',
          'border-2 border-white text-white hover:bg-white hover:text-purple-3 focus-visible:ring-4 focus-visible:ring-white/25':
            variant === 'tertiary',
          'text-khaki-1 bg-gradient-danger hover:bg-red hover:bg-none focus-visible:ring-4 focus-visible:ring-red/50':
            variant === 'danger',
          'hover:underline focus-visible:underline': variant.includes('minimal'),
          'text-slate': variant === 'minimal',
          'text-khaki-1': variant === 'minimal-knockout',
          'text-red ': variant === 'minimal-danger',
          'bg-dark text-khaki-1': variant === 'dark',
        },
        className,
      )}
      ref={forwardedRef}
      {...props}
    >
      {state === 'waiting' && (
        <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
          <Spinner />
        </div>
      )}
      <span
        className={clsx('flex gap-2 justify-center items-center', {
          'opacity-0': state === 'waiting',
        })}
      >
        {children}
      </span>
    </Component>
  ),
) as PolymorphicButton;

Button.displayName = 'Button';
