import * as React from 'react';

import { useUser } from '@clerk/nextjs';
import { useRouter } from 'next/router';

import { AccountLayout, SiteFooter, SiteHeader, SplitLayout } from '@/components/layout';
import { Drawer, Modal, useUI, useUIDispatch } from '@/components/ui';
import { WorkoutPlayer } from '@/components/workouts';
import { useAnalyticsDispatch } from '@/context/analytics';
import { getUserMetadata } from '@/utils/auth/getUserMetadata';
import { trpc } from '@/utils/trpc';

const disabledAnalyticsPaths = new Set<`/${string}`>(['/admin', '/callback']);

interface AppLayoutProps {
  children: React.ReactNode;
}

export const AppLayout = React.memo<AppLayoutProps>(({ children }) => {
  const {
    drawerContent,
    drawerVisible,
    drawerClassName,
    drawerPosition,
    drawerOnClose,
    modalVisible,
    modalContent,
    modalOnClose,
    workoutPlayerPlayback,
    workoutPlayerWorkout,
    workoutPlayerVisible,
  } = useUI();

  const uiDispatch = useUIDispatch();
  const analyticsDispatch = useAnalyticsDispatch();

  const router = useRouter();
  const isDisabledAnalyticsPath = disabledAnalyticsPaths.has(`/${router.pathname.split('/')[1]}`);

  const { user, isLoaded } = useUser();
  const { userId } = getUserMetadata(user);

  const { data: dbUser } = trpc.users.byId.useQuery(
    { id: userId || '' },
    {
      enabled: !!userId,
    },
  );

  // Segment page track
  React.useEffect(() => {
    // We need to wrap it in a rAF to ensure the correct data is sent to Segment
    // https://github.com/vercel/next.js/issues/6025
    if (!isDisabledAnalyticsPath)
      requestAnimationFrame(() =>
        analyticsDispatch({
          type: 'PAGE_VIEW',
          payload: { asPath: router.asPath },
        }),
      );
  }, [analyticsDispatch, isDisabledAnalyticsPath, router.asPath]);

  React.useEffect(() => {
    if (!isDisabledAnalyticsPath && isLoaded && dbUser) {
      analyticsDispatch({
        type: 'INIT_IDENTIFY',
        payload: { dbUser: dbUser.data },
      });
    }
  }, [isLoaded, dbUser, isDisabledAnalyticsPath, analyticsDispatch]);

  const renderChildren = React.useCallback(() => {
    if (router.pathname.startsWith('/admin') || router.pathname.startsWith('/signup/collective/welcome'))
      return <>{children}</>;

    if (router.pathname.startsWith('/signup') || router.pathname.startsWith('/signin')) {
      return (
        <SplitLayout
          image={router.pathname.endsWith('/signup/welcome')}
          video={!router.pathname.endsWith('/signup/welcome')}
        >
          {children}
        </SplitLayout>
      );
    }

    return (
      <>
        <SiteHeader />
        <main className='grow w-full mx-auto overflow-x-hidden'>
          {router.pathname.startsWith('/account') ? (
            <AccountLayout showTabs={router.pathname.startsWith('/account/settings')}>{children}</AccountLayout>
          ) : (
            children
          )}
        </main>
        <SiteFooter />
      </>
    );
  }, [children, router.pathname]);

  return (
    <>
      {renderChildren()}
      {workoutPlayerWorkout && workoutPlayerPlayback && (
        <WorkoutPlayer
          onClose={() => uiDispatch({ type: 'WORKOUT_PLAYER_CLOSE' })}
          open={workoutPlayerVisible}
          playback={workoutPlayerPlayback}
          workout={workoutPlayerWorkout}
        />
      )}
      <Modal
        onClose={() => {
          uiDispatch({ type: 'MODAL_CLOSE' });
          modalOnClose?.();
        }}
        open={modalVisible}
      >
        {React.isValidElement(modalContent) && modalContent}
      </Modal>
      <Drawer
        className={drawerClassName}
        onClose={() => {
          uiDispatch({ type: 'DRAWER_CLOSE' });
          drawerOnClose?.();
        }}
        open={drawerVisible}
        position={drawerPosition}
      >
        {React.isValidElement(drawerContent) && drawerContent}
      </Drawer>
    </>
  );
});

AppLayout.displayName = 'AppLayout';
