import * as React from 'react';

import format from 'date-fns/format';

import { ButtonLink } from '@/components/common';
import { Avatar } from '@/components/ui';
import { resolveName } from '@/utils/resolveName';
import { getInitials } from '@/utils/string';

import { RouterOutput } from '../../../trpc/router';
import { ZodiacSignIcon, ZodiacSigns, ZodiacSprite } from '../BirthdayZodiacInput/ZodiacIcons';

type AccountUserCardProps = {
  dbUser: RouterOutput['users']['byId']['data'];
  username?: string;
};

export const AccountUserCard = React.memo<AccountUserCardProps>(({ dbUser, username }) => {
  return (
    <div className='bg-khaki-1 rounded-xl pb-5 sm:pb-8  relative'>
      <div className='relative  p-4 sm:py-6 px-4 sm:px-8 border-b border-b-khaki-2'>
        <div className='flex items-center gap-x-4 lg:gap-x-5 relative z-20'>
          <Avatar letters={getInitials(resolveName(dbUser, username))} variant='lg' />
          <div>
            <div className='inline-flex gap-3'>
              <h2 className='font-lato text-lg lg:text-2xl tracking-tighter font-bold'>
                {resolveName(dbUser, username) || dbUser.email}
              </h2>
            </div>
          </div>
        </div>
        <div className='absolute right-0 inset-y-0 w-1/2 z-10 overflow-hidden after:absolute after:z-20 after:inset-y-0 after:w-1/2 after:bg-gradient-to-r after:from-khaki-1'>
          <div className="absolute z-10 -right-4 -top-1/4 h-[200%] w-full -rotate-12 bg-[url('/patterns/horoscope-space.svg')] bg-[length:180px] sm:bg-[length:240px]" />
        </div>
      </div>
      <div className='px-4 sm:px-8 pt-6'>
        <div className='flex flex-wrap gap-3 sm:gap-x-6 text-sm items-center mb-5 text-slate-1'>
          <div>
            <b>Member since:</b> {format(new Date(dbUser.createdAt), 'MMM do, yyyy')}
          </div>
          {dbUser.birthday && (
            <div>
              <b>Birthday:</b> {format(new Date(dbUser.birthday), 'MMM do, yyyy')}
            </div>
          )}
          {dbUser.zodiacSign && (
            <div className='flex items-center gap-1'>
              <b>Zodiac Sign:</b> {dbUser.zodiacSign}{' '}
              <ZodiacSignIcon className='w-5 h-5' sign={dbUser.zodiacSign as ZodiacSigns} />
            </div>
          )}
        </div>
        <ButtonLink className='' href='/account/settings' size='sm' variant='secondary'>
          Adjust my Profile
        </ButtonLink>
      </div>
      <ZodiacSprite />
    </div>
  );
});

AccountUserCard.displayName = 'AccountUserCard';
