import * as React from 'react';

import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { OrDivider } from '@/components/common';
import { CycleCalendarSlider, CycleDurationSlider, LunarSwitch } from '@/components/cycle';
import { PhaseProgress } from '@/components/phases';
import { Button, PhaseColorText } from '@/components/ui';
import { useAnalyticsDispatch } from '@/context/analytics';
import { getLastNewMoonDate } from '@/utils/date';
import { trpc } from '@/utils/trpc';

import { RouterOutput } from '../../../trpc/router';

interface AccountCycleUpdateFormProps {
  dbUser: RouterOutput['users']['byId']['data'];
}

export const AccountCycleUpdateForm = React.memo<AccountCycleUpdateFormProps>(({ dbUser: user }) => {
  const router = useRouter();
  const utils = trpc.useContext();

  const lastNewMoon = React.useMemo(() => getLastNewMoonDate(), []);

  const { mutateAsync, isLoading } = trpc.users.update.useMutation({
    onMutate: () => toast.loading('Saving...'),
    onSuccess: async (resp) => {
      await Promise.all([
        utils.users.workout.invalidate(undefined, {
          refetchType: 'all',
          exact: false,
        }),
        utils.workouts.recommended.invalidate(),
        utils.videos.invalidate(),
      ]);
      toast.remove();
      toast.success(`Account updated`);

      analyticsDispatch({
        type: 'CYCLE_UPDATED',
        payload: { dbUser: resp?.data },
      });
      analyticsDispatch({
        type: 'EXPLICIT_IDENTIFY',
        payload: { dbUser: resp?.data },
      });

      router.push('/account');
    },
    onError: (error) => {
      toast.remove();
      toast.error(error.message);
    },
  });

  const {
    setValue,
    watch,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      cycleDuration: user.cycleDuration,
      cycleLunar: user.cycleLunar,
      cycleStart: user.cycleStart,
    },
  });

  const lunarEnabled = watch('cycleLunar');

  const analyticsDispatch = useAnalyticsDispatch();

  const onSubmit = handleSubmit(({ cycleStart, cycleLunar, cycleDuration }) => {
    mutateAsync({
      id: user.id,
      cycleStart: cycleStart.toISOString(),
      cycleLunar,
      cycleDuration,
    });
  });

  return (
    <form className='bg-khaki-1 rounded-lg p-5 sm:p-8 overflow-hidden mx-auto max-w-3xl' onSubmit={onSubmit}>
      <div>
        <h2 className='font-lato text-lg lg:text-xl text-slate font-bold mb-4'>Cycle Information</h2>
        <div>
          <div>
            You are{' '}
            {user.cycleLunar
              ? 'following the lunar cycle'
              : `on day ${user.phasedayNumberRaw} of ${user.cycleDuration}`}{' '}
            in the{' '}
            <PhaseColorText
              className='font-lato tracking-tighter text-sm font-bold mt-4 uppercase'
              phaseName={user.phaseName}
            >
              {user.phaseName}
            </PhaseColorText>{' '}
            phase.
          </div>
          <PhaseProgress
            animate={false}
            className='max-w-md mt-5 mb-8'
            currentPhase={user.phaseName}
            currentPhaseDay={user.phasedayNumber}
            dynamicColors={false}
          />
        </div>
        <CycleCalendarSlider
          defaultValue={user.cycleStart ? new Date(user.cycleStart) : undefined}
          disabled={lunarEnabled}
          onChange={(value) => setValue('cycleStart', value)}
        />
      </div>
      <CycleDurationSlider
        defaultValue={user.cycleDuration}
        disabled={lunarEnabled}
        max={40}
        min={21}
        onChange={(value) => setValue('cycleDuration', value)}
        subtitle='Every woman is unique! Adjust to specify your typical cycle length (the number of days between your periods) to
        receive a more accurate experience.'
        title='Average Cycle Duration'
      />
      <OrDivider />
      <LunarSwitch
        disabled={isLoading}
        enabled={lunarEnabled}
        lastNewMoon={lastNewMoon}
        setEnabled={(enabled) => setValue('cycleLunar', enabled)}
        showDescription={true}
      />
      <div className='flex gap-x-6 mt-2 -mx-5 -mb-5 px-5 sm:-mx-8 sm:-mb-8 sm:px-8 py-5 rounded-bl-lg bg-khaki-2-3'>
        <Button disabled={isSubmitting} state={isSubmitting ? 'waiting' : undefined} type='submit' variant='primary'>
          Save
        </Button>
      </div>
    </form>
  );
});

AccountCycleUpdateForm.displayName = 'AccountCycleUpdateForm';
