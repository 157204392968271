import { Video, VideoPlayback, Prisma } from '@prisma/client';
import type { toZod } from 'tozod';
import { z } from 'zod';

// Video
export const videoSchema: toZod<Video> = z.object({
  aspectRatio: z.string().nullable(),
  createdAt: z.date(),
  duration: z.number().nullable(),
  fileName: z.string(),
  id: z.string(),
  isPreview: z.boolean(),
  maxStoredResolution: z.string().nullable(),
  muxAssetId: z.string().nullable(),
  status: z.string(),
  title: z.string(),
  slug: z.string(),
  updatedAt: z.date(),
  uploadUrl: z.string(),
  mp4support: z.boolean(),
});

// VideoPlayback
export const videoPlaybackSchema: toZod<VideoPlayback> = z.object({
  id: z.string(),
  muxPlaybackId: z.string(),
  policy: z.string(),
  videoId: z.string(),
});

/**
 * Video with playbacks
 */
const videoWithPlaybacks = Prisma.validator<Prisma.VideoArgs>()({
  include: { playbacks: true },
});

type VideoWithPlaybacks = Prisma.VideoGetPayload<typeof videoWithPlaybacks>;

export const videoWithPlaybacksSchema: toZod<VideoWithPlaybacks> = videoSchema.extend({
  playbacks: z.array(videoPlaybackSchema),
});
