import * as React from 'react';

import { SubscriptionOverview } from '@/components/account';

import { RouterOutput } from '../../../trpc/router';

type AccountSubscriptionProps = {
  dbUser: RouterOutput['users']['byId']['data'];
};

export const AccountSubscription = React.memo<AccountSubscriptionProps>(({ dbUser }) => {
  return (
    <div className='bg-khaki-1 rounded-xl px-4 py-5 sm:py-8 sm:px-6'>
      <h2 className='font-lato text-lg lg:text-xl text-slate font-bold mb-4'>My Subscription</h2>
      <SubscriptionOverview dbUser={dbUser} />
    </div>
  );
});

AccountSubscription.displayName = 'AccountSubscription';
