import * as React from 'react';

import clsx from 'clsx';
import NextImage from 'next/image';
import { twMerge } from 'tailwind-merge';

interface SectionHeaderProps {
  title?: string;
  description: string | React.ReactNode;
  image?: string;
  className?: string;
  classNameHeading?: string;
  headingChildren?: React.ReactNode;
}

export const SectionHeader = React.memo<SectionHeaderProps>(
  ({ title, description, image, className, classNameHeading, headingChildren }) => (
    <header className={clsx(twMerge('text-center px-6 md:px-12 max-w-xl mx-auto', className))}>
      {image && (
        <div className='w-14 md:w-20 mb-4 md:mb-6 mx-auto leading-none'>
          <NextImage alt={title ?? ''} height={80} src={image} width={80} />
        </div>
      )}
      {headingChildren ? (
        headingChildren
      ) : (
        <h3
          className={twMerge(
            'font-lato text-2xl md:text-3xl uppercase tracking-widest font-bold bg-clip-text text-transparent bg-gradient-yellow-to-purple',
            classNameHeading,
          )}
        >
          {title}
        </h3>
      )}
      <div className='md:mt-2'>{description}</div>
    </header>
  ),
);

SectionHeader.displayName = 'SectionHeader';
