import * as React from 'react';

import clsx from 'clsx';

import { useSlider } from '@/hooks/useSlider';

import s from './VolumeSlider.module.css';

interface VolumeSliderProps {
  onScrub: (value: number) => void;
  position: number;
  className?: string;
}

export const VolumeSlider = React.memo<VolumeSliderProps>(({ className, onScrub, position }) => {
  const sliderRef = React.useRef(null);

  const { value: sliderValue, isSliding } = useSlider(sliderRef, { onScrub });

  return (
    <div
      className={clsx('relative', s.root, className)}
      ref={sliderRef}
      style={
        {
          '--progress-filled': isSliding ? sliderValue : position,
        } as React.CSSProperties
      }
    >
      <div className={clsx('w-full, h-12 ', s.track)}>
        <div
          className={clsx(
            'absolute top-1/2 left-0 w-full pointer-events-none bg-white/10 -translate-y-1/2 rounded-full',
            s.track_base,
          )}
        />
        <div
          className={clsx(
            'bg-white absolute top-1/2 left-0 pointer-events-none -translate-y-1/2 rounded-l-full',
            s.track_filled,
          )}
        />
        <div
          className={clsx(
            'border-[3px] border-white bg-white rounded-full -translate-x-1/2 -translate-y-1/2 absolute top-1/2 pointer-events-none',
            s.knob,
          )}
        />
      </div>
    </div>
  );
});

VolumeSlider.displayName = 'VolumeSlider';
