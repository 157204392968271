import * as React from 'react';

import { Carousel } from '@/components/ui';
import { WorkoutTeaserCard } from '@/components/workouts';
import { useAnalyticsDispatch } from '@/context/analytics';
import { trpc } from '@/utils/trpc';

import { RouterOutput } from '../../../trpc/router';

type AccountRecentWorkoutsProps = {
  dbUser: RouterOutput['users']['byId']['data'];
};

export const AccountRecentWorkouts = React.memo<AccountRecentWorkoutsProps>(({ dbUser }) => {
  const { data: recentWorkouts, isLoading } = trpc.users.workouts.recent.useQuery({ id: dbUser.id });

  const gooseEgg = React.useMemo(() => !isLoading && !recentWorkouts?.length, [isLoading, recentWorkouts?.length]);
  const skeletons = React.useMemo(() => 3 - (recentWorkouts?.length ?? 0), [recentWorkouts?.length]);

  const analyticsDispatch = useAnalyticsDispatch();

  return (
    <div className='bg-khaki-1 rounded-xl py-5 sm:py-8'>
      <h2 className='font-lato px-6 text-lg lg:text-xl text-slate font-bold mb-4'>Recently Watched Workouts</h2>
      {gooseEgg && (
        <div className='text-slate-1 font-sm px-6 mb-4 -mt-2'>Your recently watched videos will show up here.</div>
      )}
      <Carousel fluidGutters={false} itemGap={16}>
        {recentWorkouts?.map((workout) => (
          <WorkoutTeaserCard
            className='min-w-[240px] max-w-[240px] md:min-w-0'
            key={workout.id}
            onClickHandler={() =>
              analyticsDispatch({
                type: 'WORKOUT_VIEWED',
                payload: {
                  dbUser,
                  location: 'Account: Recently Watched Workouts',
                  workout: workout,
                },
              })
            }
            url={`/workouts/${workout.slug}`}
            variant='simple'
            workout={workout}
          />
        ))}
        {(isLoading || skeletons > 0) &&
          [...Array(skeletons)].map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <WorkoutTeaserCard animateSkeleton={isLoading} key={i} variant='simple' />
          ))}
      </Carousel>
    </div>
  );
});

AccountRecentWorkouts.displayName = 'AccountRecentWorkouts';
