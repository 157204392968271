import * as React from 'react';

import clsx from 'clsx';

import { phaseSegments } from '@/utils/phase';

export type PhaseProgressProps = {
  animate?: boolean;
  currentPhase: string;
  currentPhaseDay: number;
  dynamicColors?: boolean;
  height?: number;
  width?: number;
  className?: string;
};

const bg: Record<string, string> = {
  Menstrual: '#6391CC',
  Follicular: '#6CA851',
  Ovulatory: '#EBD57F',
  Luteal: '#A9709D',
};

const initialWait = 250;
const transitionTiming = 300;

export const PhaseProgress = React.memo<PhaseProgressProps>(
  ({ animate = true, currentPhase, currentPhaseDay, dynamicColors = true, height = 8, className }) => {
    const segmentRefs = React.useRef<HTMLDivElement[]>([]);
    const pointerRefs = React.useRef<HTMLDivElement[]>([]);

    const currentPhaseIdx = React.useMemo(
      () => phaseSegments.findIndex((segment) => segment.name === currentPhase),
      [currentPhase],
    );
    const phase = React.useMemo(() => phaseSegments.find((segment) => segment.name === currentPhase), [currentPhase]);
    const currentPhasedayIdx = React.useMemo(
      () => phase?.days.findIndex((day) => day === currentPhaseDay),
      [currentPhaseDay, phase?.days],
    );

    const getProgressInPhase = React.useCallback(() => {
      if (!phase || !currentPhasedayIdx || currentPhasedayIdx < 0) return 0;

      return Math.round(((currentPhasedayIdx + 1) / phase.days.length) * 100) - 12;
    }, [currentPhasedayIdx, phase]);

    React.useEffect(() => {
      if (animate) {
        segmentRefs.current.forEach((segment) => (segment.style.width = ''));
        segmentRefs.current.forEach((segment) => segment.classList.remove('w-full'));
        segmentRefs.current.forEach((segment) => segment.classList.add('w-0'));

        setTimeout(() => {
          segmentRefs.current.forEach((segment, i) => {
            if (currentPhaseIdx > -1 && currentPhaseIdx >= i) {
              setTimeout(() => {
                segment.classList.remove('overflow-hidden');
                segment.classList.remove('w-0');
                segment.classList.add('rounded-r-full');
                segment.style.backgroundColor = phaseSegments[i].color;
                if (currentPhaseIdx === i) {
                  const addLilSumSum =
                    currentPhasedayIdx === 0 ? 4 : currentPhaseIdx + 1 === phase?.days.length ? -4 : 0;
                  segment.style.width = `calc(${getProgressInPhase()}% + ${addLilSumSum}px)`;
                } else {
                  segment.classList.add('w-full');
                  setTimeout(() => {
                    segment.classList.add('overflow-hidden');
                    segment.classList.remove('rounded-r-full');
                    segment.style.backgroundColor = '';
                  }, transitionTiming);
                }
              }, transitionTiming * (i + 1));
            }
          });
        }, initialWait);
      } else {
        const currentPhaseSegmentIndex = phaseSegments.findIndex((segment) => segment.name === currentPhase);
        segmentRefs.current[currentPhaseSegmentIndex].classList.remove('overflow-hidden');
        pointerRefs.current[currentPhaseSegmentIndex].style.right = `auto`;
        pointerRefs.current[currentPhaseSegmentIndex].style.left = `calc(${getProgressInPhase()}% - ${
          currentPhasedayIdx === 0 ? 6 : 12
        }px)`;
      }
    }, [animate, currentPhase, currentPhaseIdx, currentPhasedayIdx, getProgressInPhase, phase?.days.length]);

    return (
      <div
        className={clsx('grid relative gap-1 my-3 w-full', className)}
        style={{
          gridTemplateColumns: `repeat(${phaseSegments.length}, 1fr)`,
          height,
        }}
      >
        {phaseSegments.map((segment, i) => (
          <div
            className='group h-full bg-[#FCFBF626] relative first-of-type:rounded-l-full last-of-type:rounded-r-full'
            key={segment.name}
          >
            <div
              className={clsx(
                'absolute top-0 left-0 h-full transition-all ease-linear w-0 overflow-hidden',
                'group-first-of-type:first-of-type:rounded-l-full group-last-of-type:last-of-type:rounded-r-full',
                { 'bg-[#FCFBF626]': currentPhase !== segment.name }, // default if not current phase
                {
                  'bg-[#FCFBF680]':
                    currentPhase !== segment.name && phaseSegments.findIndex((s) => s.name === currentPhase) > i,
                }, // if phase has already passed
                { 'bg-[#FCFBF6BF]': currentPhase === segment.name }, // if is current phase
              )}
              ref={(el) => {
                if (el) {
                  segmentRefs.current[i] = el;
                }
              }}
              style={{
                transitionDuration: `${transitionTiming}ms`,
                backgroundColor: dynamicColors
                  ? segment.name === currentPhase
                    ? segment.color
                    : undefined
                  : segment.color,
                ...(animate ? {} : { width: '100%' }),
              }}
            >
              <div
                className='absolute mt-0.5 translate-y-full right-0 translate-x-1/2'
                ref={(el) => {
                  if (el) {
                    pointerRefs.current[i] = el;
                  }
                }}
              >
                <svg fill='none' height='10' viewBox='0 0 12 10' width='12' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M5.87584 0.5L11.072 9.5H0.679688L5.87584 0.5Z'
                    fill={currentPhase === segment.name ? bg[currentPhase] : '#FCFBF6'}
                  />
                </svg>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  },
);

PhaseProgress.displayName = 'PhaseProgress';
