import * as React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Phaseday, Image, AnimalEnergy, FertilityStatus } from '@prisma/client';
import { useRouter } from 'next/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { z } from 'zod';

import { FormErrorMessage } from '@/components/common';
import { ImageSelectField } from '@/components/images';
import { Button, Input, Radio, Textarea } from '@/components/ui';
import { useLeavePrevention } from '@/hooks/useLeavePrevention';
import { ERROR, WARN } from '@/utils/form';
import { capitalize } from '@/utils/string';
import { trpc } from '@/utils/trpc';

type PhaseDayUpdateFormValues = Omit<Phaseday, 'id' | 'number' | 'phaseId'> & {
  phasePartnerDescription: string;
};

const schema: z.ZodType<PhaseDayUpdateFormValues> = z.lazy(() =>
  z.object({
    animalEnergy: z.enum(['BUTTERFLY', 'SWAN', 'TIGER', 'WOLF']),
    emotional: z.string().min(1, { message: ERROR.REQUIRED }),
    heading: z.string().min(1, { message: ERROR.REQUIRED }),
    imageId: z.string().min(1, { message: ERROR.REQUIRED }),
    insightFocus: z.string().min(1, { message: ERROR.REQUIRED }), //! no longer used
    insightGuidance: z.string().min(1, { message: ERROR.REQUIRED }), //! no longer used
    insightImageId: z.string().min(1, { message: ERROR.REQUIRED }), //! no longer used
    insightInspiration: z.string().min(1, { message: ERROR.REQUIRED }), //! no longer used
    insightMantra: z.string().min(1, { message: ERROR.REQUIRED }), //! no longer used
    insightsTagline: z.string().min(1, { message: ERROR.REQUIRED }),
    nutritionFats: z.string().min(1, { message: ERROR.REQUIRED }),
    nutritionFatsList: z.string().min(1, { message: ERROR.REQUIRED }),
    nutritionFruits: z.string().min(1, { message: ERROR.REQUIRED }),
    nutritionFruitsList: z.string().min(1, { message: ERROR.REQUIRED }),
    nutritionGoal: z.string().min(1, { message: ERROR.REQUIRED }),
    nutritionGrains: z.string().min(1, { message: ERROR.REQUIRED }),
    nutritionGrainsList: z.string().min(1, { message: ERROR.REQUIRED }),
    nutritionImageId: z.string().min(1, { message: ERROR.REQUIRED }),
    nutritionProteins: z.string().min(1, { message: ERROR.REQUIRED }),
    nutritionProteinsList: z.string().min(1, { message: ERROR.REQUIRED }),
    nutritionScience: z.string().min(1, { message: ERROR.REQUIRED }),
    nutritionTagline: z.string().min(1, { message: ERROR.REQUIRED }),
    nutritionVeggies: z.string().min(1, { message: ERROR.REQUIRED }),
    nutritionVeggiesList: z.string().min(1, { message: ERROR.REQUIRED }),
    nutritionVitamins: z.string().min(1, { message: ERROR.REQUIRED }),
    nutritionVitaminsList: z.string().min(1, { message: ERROR.REQUIRED }),
    physical: z.string().min(1, { message: ERROR.REQUIRED }),
    workoutTagline: z.string().min(1, { message: ERROR.REQUIRED }),
    partnerEmotions: z.string().min(1, { message: ERROR.REQUIRED }),
    partnerPhysical: z.string().min(1, { message: ERROR.REQUIRED }),
    partnerSuggestions: z.string().min(1, { message: ERROR.REQUIRED }),
    fertilityStatus: z.enum(['FERTILE', 'NOT_FERTILE', 'PEAK']),
    phasePartnerDescription: z.string().min(1, { message: ERROR.REQUIRED }),
  }),
);

export interface PhasedayUpdateForm {
  phaseday: Phaseday & {
    image: Image;
    insightImage: Image;
    nutritionImage: Image;
    phase: { name: string; partnerDescription: string };
  };
}

export const PhasedayUpdateForm = React.memo<PhasedayUpdateForm>(({ phaseday }) => {
  const router = useRouter();
  const utils = trpc.useContext();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isDirty, isSubmitSuccessful },
  } = useForm<PhaseDayUpdateFormValues>({
    defaultValues: {
      animalEnergy: phaseday.animalEnergy,
      emotional: phaseday.emotional,
      heading: phaseday.heading,
      imageId: phaseday.imageId,
      insightFocus: phaseday.insightFocus,
      insightGuidance: phaseday.insightGuidance,
      insightImageId: phaseday.insightImageId,
      insightInspiration: phaseday.insightInspiration,
      insightMantra: phaseday.insightMantra,
      insightsTagline: phaseday.insightsTagline,
      nutritionFats: phaseday.nutritionFats,
      nutritionFatsList: phaseday.nutritionFatsList,
      nutritionFruits: phaseday.nutritionFruits,
      nutritionFruitsList: phaseday.nutritionFruitsList,
      nutritionGoal: phaseday.nutritionGoal,
      nutritionGrains: phaseday.nutritionGrains,
      nutritionGrainsList: phaseday.nutritionGrainsList,
      nutritionImageId: phaseday.nutritionImageId,
      nutritionProteins: phaseday.nutritionProteins,
      nutritionProteinsList: phaseday.nutritionProteinsList,
      nutritionScience: phaseday.nutritionScience,
      nutritionTagline: phaseday.nutritionTagline,
      nutritionVeggies: phaseday.nutritionVeggies,
      nutritionVeggiesList: phaseday.nutritionVeggiesList,
      nutritionVitamins: phaseday.nutritionVitamins,
      nutritionVitaminsList: phaseday.nutritionVitaminsList,
      physical: phaseday.physical,
      workoutTagline: phaseday.workoutTagline,
      partnerEmotions: phaseday.partnerEmotions,
      partnerPhysical: phaseday.partnerPhysical,
      partnerSuggestions: phaseday.partnerSuggestions,
      fertilityStatus: phaseday.fertilityStatus,
      phasePartnerDescription: phaseday.phase.partnerDescription,
    },
    resolver: zodResolver(schema),
  });

  useLeavePrevention(isDirty && !isSubmitting && !isSubmitSuccessful, WARN.UNSAVED_CHANGES);

  const { mutate } = trpc.phasedays.update.useMutation({
    onMutate: () => {
      toast.loading('Updating phaseday...');
    },
    onError: async ({ message }) => {
      toast.remove();
      toast.error(message);
    },
    onSuccess: async (data) => {
      toast.remove();
      toast.success(`Phaseday ${data.number} updated`);
      await Promise.all([utils.phasedays.all.invalidate(), utils.phasedays.byId.invalidate({ id: data.id })]);
      router.push('/admin/phases');
    },
  });

  const onSubmit = React.useCallback<SubmitHandler<PhaseDayUpdateFormValues>>(
    (data) =>
      mutate({
        animalEnergy: data.animalEnergy,
        emotional: data.emotional,
        heading: data.heading,
        id: phaseday.id,
        imageId: data.imageId,
        insightFocus: data.insightFocus,
        insightGuidance: data.insightGuidance,
        insightImageId: data.insightImageId,
        insightInspiration: data.insightInspiration,
        insightMantra: data.insightMantra,
        insightsTagline: data.insightsTagline,
        nutritionFats: data.nutritionFats,
        nutritionFatsList: data.nutritionFatsList,
        nutritionFruits: data.nutritionFruits,
        nutritionFruitsList: data.nutritionFruitsList,
        nutritionGoal: data.nutritionGoal,
        nutritionGrains: data.nutritionGrains,
        nutritionGrainsList: data.nutritionGrainsList,
        nutritionImageId: data.nutritionImageId,
        nutritionProteins: data.nutritionProteins,
        nutritionProteinsList: data.nutritionProteinsList,
        nutritionScience: data.nutritionScience,
        nutritionTagline: data.nutritionTagline,
        nutritionVeggies: data.nutritionVeggies,
        nutritionVeggiesList: data.nutritionVeggiesList,
        nutritionVitamins: data.nutritionVitamins,
        nutritionVitaminsList: data.nutritionVitaminsList,
        physical: data.physical,
        workoutTagline: data.workoutTagline,
        partnerEmotions: data.partnerEmotions,
        partnerPhysical: data.partnerPhysical,
        partnerSuggestions: data.partnerSuggestions,
        fertilityStatus: data.fertilityStatus,
        phasePartnerDescription: data.phasePartnerDescription,
      }),
    [mutate, phaseday.id],
  );

  return (
    <form className='bg-khaki-1 rounded-lg p-8 overflow-hidden' onSubmit={handleSubmit(onSubmit)}>
      <Input errors={errors} label='Heading' {...register('heading')} />
      <ImageSelectField
        errors={errors}
        image={phaseday.image}
        label='Image'
        name='imageId'
        onImageSelect={(img) => setValue('imageId', img.id, { shouldDirty: true })}
      />
      <Textarea
        errors={errors}
        label='Physical (deprecated - will be removed when Web has no dependency)'
        {...register('physical')}
      />
      <Textarea
        errors={errors}
        label='Emotional (deprecated - will be removed when Web has no dependency)'
        {...register('emotional')}
      />
      <div>
        <label className='font-josefin font-bold block mb-2 tracking-tighter'>Animal Energy</label>
        <div className='flex gap-6 items-center'>
          {Object.keys(AnimalEnergy).map((value) => (
            <Radio key={value} label={capitalize(value.toLowerCase())} {...register('animalEnergy')} value={value} />
          ))}
        </div>
        <FormErrorMessage errors={errors} name='animalEnergy' />
      </div>
      <Textarea errors={errors} label='Protein' {...register('nutritionProteins')} />
      <Input errors={errors} label='Proteins List' {...register('nutritionProteinsList')} />
      <Textarea errors={errors} label='Veggies' {...register('nutritionVeggies')} />
      <Input errors={errors} label='Veggies List' {...register('nutritionVeggiesList')} />
      <Textarea errors={errors} label='Fats' {...register('nutritionFats')} />
      <Input errors={errors} label='Fats List' {...register('nutritionFatsList')} />
      <Textarea errors={errors} label='Grains' {...register('nutritionGrains')} />
      <Input errors={errors} label='Grains List' {...register('nutritionGrainsList')} />
      <Textarea errors={errors} label='Fruits' {...register('nutritionFruits')} />
      <Input errors={errors} label='Fruits List' {...register('nutritionFruitsList')} />
      <Textarea errors={errors} label='Supplements' {...register('nutritionVitamins')} />
      <Input errors={errors} label='Supplements List' {...register('nutritionVitaminsList')} />

      {/* Nutrition  */}
      <ImageSelectField
        errors={errors}
        image={phaseday.nutritionImage}
        label='Nutrition Image'
        name='nutritionImageId'
        onImageSelect={(img) => setValue('nutritionImageId', img.id, { shouldDirty: true })}
      />
      <Input errors={errors} label='Goal' {...register('nutritionGoal')} />
      <Textarea errors={errors} label='Science' {...register('nutritionScience')} />

      <Textarea errors={errors} label='Insights Tagline (Today Screen)' {...register('insightsTagline')} />
      <Textarea errors={errors} label='Workout Tagline (Today Screen)' {...register('workoutTagline')} />
      <Textarea errors={errors} label='Nutrition Tagline (Today Screen)' {...register('nutritionTagline')} />

      <h3 className='font-josefin font-bold text-2xl'>Partner Sharing Content</h3>
      <hr />
      <div>
        <Textarea
          errors={errors}
          label='Partner Sharing - Phase Description'
          {...register('phasePartnerDescription')}
        />
        <Textarea errors={errors} label='Partner Sharing - Her Body' {...register('partnerPhysical')} />
        <Textarea errors={errors} label='Partner Sharing - Her Emotions' {...register('partnerEmotions')} />
        <Textarea errors={errors} label='Partner Sharing - Suggestions' {...register('partnerSuggestions')} />
        <div>
          <label className='font-josefin font-bold block mb-2 tracking-tighter'>Fertility Status</label>
          <div className='flex gap-6 items-center'>
            {Object.keys(FertilityStatus).map((value) => (
              <Radio
                key={value}
                label={capitalize(value.toLowerCase().replaceAll('_', ' '))}
                {...register('fertilityStatus')}
                value={value}
              />
            ))}
          </div>
          <FormErrorMessage errors={errors} name='fertilityStatus' />
        </div>
      </div>

      <div className='flex gap-x-6 mt-2 -mx-8 -mb-8 p-8 py-5 rounded-bl-lg bg-khaki-2-3'>
        <Button
          disabled={isSubmitting || !isDirty}
          size='lg'
          state={isSubmitting ? 'waiting' : undefined}
          type='submit'
          variant='primary'
        >
          Update
        </Button>
      </div>
    </form>
  );
});

PhasedayUpdateForm.displayName = 'PhasedayUpdateForm';
