import * as React from 'react';

import clsx from 'clsx';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import { throttle } from 'throttle-debounce';

import s from './CycleCalendarSlider.module.css';

// create an array of dates between the two dates
function getDatesBetween(startDate: Date, endDate: Date) {
  const dates: Date[] = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }
  return dates;
}

interface CycleCalendarSeparatorProps {
  prevDate: Date | undefined;
  date: Date;
}

const CycleCalendarSeparator: React.FC<CycleCalendarSeparatorProps> = ({ prevDate, date }) => {
  // only render when starting new month
  if (!prevDate || format(prevDate, 'M') === format(date, 'M')) return null;

  return <div className='w-1 h-1 bg-khaki-4 rounded-full mx-0.5 shrink-0' />;
};

interface CycleCalendarSliderButtonProps {
  disabled?: boolean;
  setValue: React.Dispatch<React.SetStateAction<Date>>;
  date: Date;
  isActive: boolean;
}

const CycleCalendarSliderButton: React.FC<CycleCalendarSliderButtonProps> = ({
  disabled,
  date,
  setValue,
  isActive,
}) => {
  return (
    <button
      aria-label={date.toISOString()}
      className={clsx(
        s.button,
        'hover:border-purple-1',
        'focus:outline-none',
        'active:border-purple-1 active:ring-1 active:ring-purple-1/25',
        'disabled:hover:border-khaki-3 disabled:cursor-auto disabled:opacity-25',
        !isActive && 'bg-khaki-1',
        isActive && !disabled && 'bg-purple-1 text-white',
        isActive && disabled && 'bg-slate text-white',
      )}
      disabled={disabled}
      onClick={() => setValue(date)}
      title={format(date, 'MMM do')}
      type='button'
    >
      <div className='leading-none'>
        <div className='text-xs'>{format(date, 'eee')}</div>
        <div className='text-2xl font-light'>{format(date, 'dd')}</div>
        <div className={clsx('text-xs tracking-wide uppercase', isActive ? 'text-white' : 'text-slate/50')}>
          {format(date, 'MMM')}
        </div>
      </div>
    </button>
  );
};

interface CycleCalendarSliderProps {
  disabled?: boolean;
  defaultValue?: Date;
  onChange: (value: Date) => void;
}

export const CycleCalendarSlider = React.memo<CycleCalendarSliderProps>((props) => {
  const scrollRef = React.useRef<HTMLDivElement>(null);

  const now = React.useMemo(() => startOfDay(new Date()), []);
  const dates = React.useMemo(() => getDatesBetween(subDays(now, 40), now), [now]);

  const [value, setValue] = React.useState(props.defaultValue || now);

  // start at end / today
  React.useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
    }
  }, []);

  // add/remove feathering to scroll container
  React.useEffect(() => {
    const scrollEl = scrollRef.current;

    if (scrollEl) {
      const throttledFn = throttle(200, () => {
        const atStart = scrollEl.scrollLeft === 0;
        const atEnd = scrollEl.scrollWidth - scrollEl.clientWidth === scrollEl.scrollLeft;

        scrollEl.style.setProperty('--mask-left', atStart ? '0px' : '32px');
        scrollEl.style.setProperty('--mask-right', atEnd ? '0px' : '32px');
      });

      scrollEl.addEventListener('scroll', throttledFn);
      return () => scrollEl.removeEventListener('scroll', throttledFn);
    }
  }, []);

  // allow dragging
  React.useEffect(() => {
    const scrollEl = scrollRef.current;

    if (scrollEl) {
      // don't allow dragging when disabled
      if (props.disabled) {
        scrollEl.style.cursor = 'auto';
        return;
      }

      scrollEl.style.cursor = 'grab';

      let dx = 0; // how far the mouse has been moved
      const pos = { top: 0, left: 0, x: 0, y: 0 };

      const mouseDownHandler = function (e: MouseEvent) {
        e.preventDefault();

        scrollEl.style.userSelect = 'none';

        dx = 0;
        pos.left = scrollEl.scrollLeft;
        pos.x = e.clientX; // get the current mouse position

        scrollEl.addEventListener('mousemove', mouseMoveHandler);
        scrollEl.addEventListener('mouseup', mouseUpLeaveHandler);
        scrollEl.addEventListener('mouseleave', mouseUpLeaveHandler);
      };

      const mouseMoveHandler = function (e: MouseEvent) {
        scrollEl.style.cursor = 'grabbing';

        dx = e.clientX - pos.x;
        scrollEl.scrollLeft = pos.left - dx; // scroll the element
      };

      const mouseUpLeaveHandler = function (e: MouseEvent) {
        e.preventDefault();

        scrollEl.style.removeProperty('cursor');
        scrollEl.style.removeProperty('user-select');

        scrollEl.removeEventListener('mousemove', mouseMoveHandler);
        scrollEl.removeEventListener('mouseup', mouseUpLeaveHandler);
      };

      const clickHandler = function (e: MouseEvent) {
        // disable clicks when dragged
        if (Math.abs(dx) > 5) {
          e.preventDefault();
          e.stopPropagation();
        }
      };

      scrollEl.addEventListener('mousedown', mouseDownHandler);
      scrollEl.addEventListener('click', clickHandler, { capture: true });

      return () => {
        scrollEl.removeEventListener('mousedown', mouseDownHandler);
        scrollEl.removeEventListener('click', clickHandler, {
          capture: true,
        });

        scrollEl.removeEventListener('mousemove', mouseMoveHandler);
        scrollEl.removeEventListener('mouseup', mouseUpLeaveHandler);
        scrollEl.addEventListener('mouseleave', mouseUpLeaveHandler);
      };
    }
  }, [props.disabled]);

  React.useEffect(() => {
    props.onChange(value);
  }, [props, value]);

  return (
    <div className='flex flex-col'>
      <div className='text-sm font-lato font-bold mb-4'>When was the first day of your last period?</div>
      <div
        className={clsx(
          s.slider,
          'flex items-center gap-x-2 py-0.5 cursor-grab hide-scrollbar mb-2',
          props.disabled ? 'overflow-hidden' : 'overflow-x-scroll',
        )}
        ref={scrollRef}
      >
        {dates.map((date, idx) => (
          <React.Fragment key={date.toDateString()}>
            <CycleCalendarSeparator date={date} prevDate={dates[idx - 1]} />
            <CycleCalendarSliderButton
              date={date}
              disabled={props.disabled}
              isActive={date.toDateString() === value.toDateString()}
              setValue={setValue}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
});

CycleCalendarSlider.displayName = 'CycleCalendarSlider';
