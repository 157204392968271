import * as React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { z } from 'zod';

import { ButtonLink } from '@/components/common';
import { Button, Checkbox } from '@/components/ui';
import { useLeavePrevention } from '@/hooks/useLeavePrevention';
import { ERROR, WARN } from '@/utils/form';
import { trpc } from '@/utils/trpc';

import { RouterOutput } from '../../../trpc/router';

type InstructorDeleteFormValues = { confirm: boolean };

const schema = z.object({
  confirm: z.boolean({ required_error: ERROR.REQUIRED }),
});

export interface InstructorDeleteFormProps {
  instructor: RouterOutput['instructors']['byId'];
}

export const InstructorDeleteForm = React.memo<InstructorDeleteFormProps>(({ instructor }) => {
  const router = useRouter();
  const utils = trpc.useContext();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isDirty, isSubmitSuccessful },
  } = useForm<InstructorDeleteFormValues>({ resolver: zodResolver(schema) });
  const watchConfirm = watch('confirm');
  const { mutate } = trpc.instructors.deleteProcedure.useMutation({
    onMutate: () => toast.loading('Deleting...'),
    onError: ({ message }) => {
      toast.remove();
      toast.error(message);
    },
    onSuccess: async (data) => {
      await Promise.all([utils.instructors.byId.invalidate({ id: data.id }), utils.instructors.all.invalidate()]);
      toast.remove();
      toast.success(`Instructor ${data.name} deleted`);
      router.push('/admin/instructors');
    },
  });

  useLeavePrevention(isDirty && !isSubmitting && !isSubmitSuccessful, WARN.UNSAVED_CHANGES);

  const onSubmit = React.useCallback<SubmitHandler<{ confirm: boolean }>>(
    ({ confirm }) => confirm && mutate({ id: instructor.id }),
    [instructor.id, mutate],
  );

  if (instructor._count.workouts) {
    return (
      <div className='bg-khaki-1 rounded-lg p-8 overflow-hidden'>
        <h2 className='font-josefin font-bold text-2xl tracking-tighter mb-10'>
          You cannot delete {instructor.name} because they have workouts.
        </h2>
        <div className='flex gap-x-6 mt-2 -mx-8 -mb-8 p-8 py-5 rounded-bl-lg bg-khaki-2-3'>
          <ButtonLink href={`/admin/instructors/${instructor.id}`} variant='secondary'>
            Back
          </ButtonLink>
        </div>
      </div>
    );
  }

  return (
    <form className='bg-khaki-1 rounded-lg p-8 overflow-hidden' onSubmit={handleSubmit(onSubmit)}>
      <h2 className='font-josefin font-bold text-2xl tracking-tighter'>
        Are you sure you want to delete instructor {instructor.name}?
      </h2>

      <Checkbox errors={errors} label='I understand this action can not be undone.' {...register('confirm')} />

      <div className='flex gap-x-6 mt-2 -mx-8 -mb-8 p-8 py-5 rounded-bl-lg bg-khaki-2-3'>
        <Button
          disabled={isSubmitting || !watchConfirm}
          state={isSubmitting ? 'waiting' : undefined}
          type='submit'
          variant='danger'
        >
          Yes, delete this instructor
        </Button>
        <ButtonLink href={`/admin/instructors/${instructor.id}`} variant='minimal'>
          No, I changed my mind
        </ButtonLink>
      </div>
    </form>
  );
});

InstructorDeleteForm.displayName = 'InstructorDeleteForm';
