import * as React from 'react';

import clsx from 'clsx';
import { useRouter } from 'next/router';

import { Type } from '@/analytics/client';
import { Button } from '@/components/ui';
import { useAnalyticsDispatch } from '@/context/analytics';
import { useUrl } from '@/hooks/useUrl';

export interface HeroProps {
  heading?: string;
  copy?: string;
  ctaText?: string;
  copyFirst?: boolean;
  onClick?: () => void;
  centerText?: boolean;
  videoURL?: string;
  poster?: string;
}

export const Hero = React.memo<HeroProps>(
  ({
    heading,
    copy,
    ctaText,
    copyFirst,
    onClick,
    centerText,
    videoURL = 'https://stream.mux.com/zA0019bOXtqO3R8LYRUB53EYfgJrlFnfm2L0102RT9gFbk/high.mp4',
    poster = '/video/28-collective-hero-poster.jpeg',
  }) => {
    const router = useRouter();
    const videoRef = React.useRef<HTMLVideoElement>(null);
    const url = useUrl();
    const analyticsDispatch = useAnalyticsDispatch();

    React.useEffect(() => {
      if (videoRef.current) videoRef.current.play().catch(() => null); // .catch(() => null) because Safari doesn't allow programatic play in Low Power Mode
    }, [videoRef]);

    const handleClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
      (event) => {
        event.preventDefault();

        if (ctaText && process.env.NEXT_PUBLIC_AUTH0_DOMAIN) {
          analyticsDispatch({
            type: 'CTA_CLICKED',
            payload: {
              url,
              destination: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
              location: 'Hero',
              text: ctaText,
              type: Type.Button,
            },
          });
        }

        router.push('/signup/questionnaire');
      },
      [analyticsDispatch, ctaText, router, url],
    );

    return (
      <div className='grid items-end w-full'>
        <video
          className='row-start-1 col-start-1 w-auto h-[600px] md:w-full md:h-[700px] object-cover'
          loop
          muted
          playsInline
          poster={poster}
          preload='auto'
          ref={videoRef}
        >
          <source src={videoURL} type='video/mp4' />
          <p>Your browser does not support the video tag.</p>
        </video>
        {heading && (
          <div className='row-start-1 col-start-1 px-6 md:px-12 z-10 text-khaki-1 pb-12 lg:pb-28'>
            <div className='max-w-6xl mx-auto'>
              <div
                className={clsx('flex flex-col items-start', {
                  'items-center': centerText,
                  'max-w-xl ': !centerText,
                })}
              >
                <h1
                  className={clsx(
                    'font-bodoni text-3xl md:text-6xl mb-4 order-0 font-normal',
                    copyFirst && 'order-2 mb-0',
                    centerText && 'text-center',
                  )}
                >
                  {heading}
                </h1>
                {copy && <p className={clsx('text-xl order-1', copyFirst && 'mb-4')}>{copy}</p>}
                {ctaText && (
                  <Button className='mt-6 order-3' onClick={onClick ?? handleClick} variant='primary'>
                    {ctaText}
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
);

Hero.displayName = 'Hero';
