import * as React from 'react';

import clsx from 'clsx';

export const ErrorMessage: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return <div className={clsx('text-red text-sm mt-3', className)}>{children}</div>;
};

ErrorMessage.displayName = 'ErrorMessage';
