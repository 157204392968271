import * as React from 'react';

import { useUser } from '@clerk/nextjs';
import { LockSimple } from '@phosphor-icons/react';
import clsx from 'clsx';
import NextImage from 'next/image';

import { Link } from '@/components/common';
import { HorizontalList } from '@/components/ui';
import { WorkoutSlim } from '@/types/28';
import { imgixLoader } from '@/utils/images';
import { findPlayback, formatDuration } from '@/utils/mux';
import { capitalize } from '@/utils/string';
import { trpc } from '@/utils/trpc';

export interface WorkoutTeaserCardProps {
  variant?: 'default' | 'simple';
  workout?: WorkoutSlim;
  url?: string;
  className?: string;
  animateSkeleton?: boolean;
  onClickHandler?: () => void;
}

export const WorkoutTeaserCard = React.memo<WorkoutTeaserCardProps>(
  ({ variant = 'default', workout, url, className, animateSkeleton, onClickHandler }) => {
    const { user } = useUser();

    const { data: playbacks } = trpc.videos.playbacks.useQuery(
      { workoutId: workout?.id ?? '', videoId: workout?.videoId ?? '' },
      {
        enabled: !!user && !!workout,
        select: (data) => data?.playbacks ?? [],
      },
    );

    const playback = React.useMemo(() => findPlayback(playbacks), [playbacks]);

    if (workout && url) {
      return (
        <Link
          className={clsx(
            'group max-w-[311px] min-w-[311px] lg:min-w-[calc((100%-32px)/3)] lg:max-w-[calc((100%-32px)/3)] ',
            variant === 'default' && 'overflow-hidden rounded-2xl bg-khaki-1',
            'focus-visible:outline-purple-1',
            className,
          )}
          data-testid='workout-teaser-card'
          href={url}
          onClick={onClickHandler}
        >
          {workout.image && (
            <div className='leading-zero relative'>
              {(!playback?.muxPlaybackId || workout.isLocked) && (
                <div className='rounded-bl-2xl absolute right-0 h-10 w-10 bg-khaki-1 justify-center items-center flex'>
                  <LockSimple className='w-4 h-4' weight='fill' />
                </div>
              )}
              <NextImage
                alt={workout.title}
                className={variant === 'simple' ? 'rounded-2xl aspect-video object-cover' : 'aspect-video object-cover'}
                draggable={false}
                height={215}
                loader={imgixLoader}
                src={`/${workout.image.fileName}`}
                width={384}
              />
            </div>
          )}
          <div className={`flex ${variant === 'default' ? 'p-4 md:p-6' : 'pt-4'}`}>
            {variant === 'default' && (
              <svg
                className='shrink-0 mr-4'
                fill='none'
                height='41'
                viewBox='0 0 41 41'
                width='41'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle cx='20.5' cy='20.5664' r='19.375' stroke='#CFCA9B' strokeWidth='1.25' />
                <path
                  d='M22.1213 16.76L28.4189 20.4332L22.1221 24.0483L22.1205 24.0492L15.7588 27.7263V13.1409L22.1213 16.76Z'
                  stroke='#CFCA9B'
                  strokeWidth='1.25'
                />
              </svg>
            )}
            <div>
              <h2 className='font-lato leading-tight font-bold text-base mb-1'>{workout.title}</h2>
              <HorizontalList
                items={[
                  workout.video?.duration ? formatDuration(workout.video.duration) : '0s',
                  capitalize(workout.targetArea?.replace(/_/g, ' ').toLowerCase()),
                  capitalize(workout.intensity?.toLowerCase()) + ' Intensity',
                ]}
              />
            </div>
          </div>
        </Link>
      );
    }

    return (
      <div
        className={clsx(
          'max-w-[311px] min-w-[311px] lg:min-w-[calc((100%-32px)/3)] lg:max-w-[calc((100%-32px)/3)] w-full',
          animateSkeleton && 'animate-pulse',
          className,
        )}
      >
        <div className={clsx('bg-khaki-2-3 h-0 pb-[56.25%]', variant === 'simple' && 'rounded-2xl')} />
        <div className='mt-4'>
          <div className='h-4 w-5/6 bg-khaki-2-3 rounded' />
          <div className='h-2 w-1/3 bg-khaki-2-3 rounded mt-3' />
        </div>
      </div>
    );
  },
);

WorkoutTeaserCard.displayName = 'WorkoutTeaserCard';
