import { Instructor, Prisma } from '@prisma/client';
import type { toZod } from 'tozod';
import { z } from 'zod';

import { imageSchema } from './image';

export const instructorSchema: toZod<Omit<Instructor, 'biography' | 'extra' | 'imagesOnInstructorsId'>> = z.object({
  createdAt: z.date(),
  id: z.string(),
  name: z.string(),
  photoId: z.string().nullable(),
  slug: z.string(),
  updatedAt: z.date(),
  workoutViews: z.number(),
});

/**
 * Instructor with photo
 */
const instructorWithPhoto = Prisma.validator<Prisma.InstructorArgs>()({
  include: { photo: true },
});

type InstructorWithPhoto = Omit<
  Prisma.InstructorGetPayload<typeof instructorWithPhoto>,
  'biography' | 'extra' | 'imagesOnInstructorsId'
>;

export const instructorWithPhotoSchema: toZod<InstructorWithPhoto> = instructorSchema.extend({
  photo: imageSchema.nullable(),
});
