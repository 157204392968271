import * as React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Star } from '@phosphor-icons/react';
import clsx from 'clsx';
import { toast } from 'react-hot-toast';

import { userWithIdentity } from '@/utils/auth0/client';
import { trpc } from '@/utils/trpc';

export type WorkoutFivePointRatingProps = {
  workoutId: string;
  color?: 'white' | 'orange';
};

export const WorkoutFivePointRating = React.memo<WorkoutFivePointRatingProps>(({ workoutId, color = 'orange' }) => {
  const { user: auth0User, getAccessTokenSilently } = useAuth0();
  const identity = userWithIdentity(auth0User);
  const userId = identity && identity['https://28.co/userId'];

  const utils = trpc.useContext();
  const { data, isLoading: isLoadingRating } = trpc.users.workoutStats.ratings.fivePoint.useQuery(
    { id: userId || '', workoutId },
    { enabled: !!userId, trpc: { context: { getAccessTokenSilently } } },
  );
  const { mutate, isLoading: isLoadingRatingMutate } = trpc.workouts.rate.fivePoint.useMutation({
    onMutate: async ({ rating }) => {
      // cancel outgoing refetches (so they don't overwrite our optimistic update)
      await utils.users.workoutStats.ratings.fivePoint.cancel({
        id: userId || '',
        workoutId,
      });

      // snapshot the previous value
      const prevRating = utils.users.workoutStats.ratings.fivePoint.getData({ id: userId || '', workoutId });

      // optimistically update to the new value
      utils.users.workoutStats.ratings.fivePoint.setData({ id: userId || '', workoutId }, { rating });

      // return a context object with the snapshotted value
      return { prevRating };
    },
    onError: (_, __, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      utils.users.workoutStats.ratings.fivePoint.setData({ id: userId || '', workoutId }, context?.prevRating);
      toast.error('Failed to rate workout. Please try again later.');
    },
    onSettled: () => {
      utils.users.workoutStats.ratings.fivePoint.invalidate({
        id: userId || '',
        workoutId,
      });
    },
    trpc: { context: { getAccessTokenSilently } },
  });

  return (
    <div className='flex gap-x-3'>
      {Array.from({ length: 5 }, (_, i) => (
        <button
          className={clsx(
            color === 'orange' ? 'text-orange-1' : 'text-white',
            isLoadingRatingMutate && 'cursor-progress',
            isLoadingRating && 'opacity-50',
          )}
          disabled={isLoadingRating || isLoadingRatingMutate}
          key={i}
          onClick={() => mutate({ id: workoutId, rating: i + 1 })}
        >
          <Star size={20} weight={data?.rating && data.rating >= i + 1 ? 'fill' : 'regular'} />
        </button>
      ))}
    </div>
  );
});

WorkoutFivePointRating.displayName = 'WorkoutFivePointRating';
