import * as React from 'react';

import clsx from 'clsx';

export const PageTitle = React.memo<React.ComponentPropsWithoutRef<'h1'>>(({ children, className, ...props }) => (
  <h1
    className={clsx(
      'mt-4 md:mt-6 mb-8 md:mb-11 font-bodoni font-normal text-[40px] md:text-6xl leading-[100%] text-center px-6 md:px-12 mx-auto max-w-4xl',

      className,
    )}
    {...props}
  >
    {children}
  </h1>
));

PageTitle.displayName = 'PageTitle';
