import * as React from 'react';

import clsx from 'clsx';
import NextImage from 'next/image';
import { useRouter } from 'next/router';
import { twMerge } from 'tailwind-merge';

import { Type } from '@/analytics/client';
import { Button } from '@/components/ui';
import { useAnalyticsDispatch } from '@/context/analytics';
import { useUrl } from '@/hooks/useUrl';

type BillboardVariant = 'default' | 'wide' | 'wider';
export interface BillboardProps {
  classNameHeading?: string;
  image: string;
  kicker?: string;
  heading: string;
  ctaText?: string;
  variant?: BillboardVariant;
  onClick?: () => void;
}

export const Billboard = React.memo<BillboardProps>(
  ({ image, heading, kicker, ctaText, variant = 'default', onClick, classNameHeading }) => {
    const router = useRouter();
    const url = useUrl();
    const analyticsDispatch = useAnalyticsDispatch();

    const handleClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
      (event) => {
        event.preventDefault();

        if (process.env.NEXT_PUBLIC_AUTH0_DOMAIN && ctaText) {
          analyticsDispatch({
            type: 'CTA_CLICKED',
            payload: {
              url,
              destination: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
              location: 'Billboard',
              text: ctaText,
              type: Type.Button,
            },
          });
        }

        router.push('/signup/questionnaire');
      },
      [analyticsDispatch, ctaText, router, url],
    );

    return (
      <div
        className={clsx('relative flex items-center p-6 sm:px-28 text-khaki-1 lg:min-h-[840px]', {
          'min-h-[480px]': variant === 'wide',
          'min-h-[481px]': variant === 'wider',
          'min-h-[375px] max-w-6xl mx-auto': variant === 'default',
        })}
      >
        <div className='absolute inset-0 -z-10 justify-center'>
          <NextImage
            alt={heading}
            className='object-cover'
            fill
            priority={true}
            quality={90}
            sizes='100vw'
            src={image}
          />
        </div>
        <div
          className={clsx('', {
            'text-center max-w-2xl mx-auto': variant === 'wide',
            'text-center max-w-5xl mx-auto': variant === 'wider',
            'max-w-md': variant === 'default',
          })}
        >
          {kicker && <div className='mb-3 text-xs font-lato font-normal uppercase tracking-widest'>{kicker}</div>}
          <h2
            className={twMerge(
              clsx(
                'mb-0 font-bodoni leading-none tracking-tight text-5xl',
                variant === 'wide' ? 'lg:text-[104px]' : 'md:text-6xl',
                variant === 'wider' && 'lg:text-[84px]',
              ),
              classNameHeading,
            )}
          >
            {heading}
          </h2>
          {ctaText && (
            <Button className='mt-8' onClick={onClick ?? handleClick} variant='primary'>
              {ctaText}
            </Button>
          )}
        </div>
      </div>
    );
  },
);

Billboard.displayName = 'Billboard';
