import * as React from 'react';

import { Image } from '@prisma/client';
import toast from 'react-hot-toast';

import { useUploadFile } from '@/hooks/useUploadfile';
import { readUploadedFileAsImage } from '@/utils/file';
import { trpc } from '@/utils/trpc';

export type UseImageCreateOpts = {
  onSuccess?: (data: Image) => void;
};

export type ImageCreateInput = {
  title: string;
  files: FileList;
};

export const useImageCreate = ({ onSuccess }: UseImageCreateOpts) => {
  const { isUploading, uploadProgress, upload } = useUploadFile();

  const { mutate, ...rest } = trpc.images.create.useMutation({
    onMutate: () => toast.loading('Saving...'),
    onError: ({ message }) => {
      toast.remove();
      toast.error(message);
    },
    onSuccess: (data) => {
      toast.remove();
      toast.success(`Image ${data.title} created`);
      onSuccess?.(data);
    },
  });

  const createImage = React.useCallback(
    async (input: ImageCreateInput) => {
      const { title, files } = input;
      const file = files[0];

      const { width, height } = await readUploadedFileAsImage(file);
      const { url, fileName } = await upload(file);

      mutate({
        fileName,
        height,
        title: title.trim(),
        type: file.type,
        url,
        width,
      });
    },
    [mutate, upload],
  );

  return {
    ...rest,
    createImage,
    isUploading,
    progress: uploadProgress,
  };
};
