import * as React from 'react';

import clsx from 'clsx';

import { Link } from '@/components/common';
import { Avatar } from '@/components/ui';
import { getInitials } from '@/utils/string';

export interface GreetingProps {
  username: string | undefined;
  daysLeft: number;
  userCycleDuration: number;
  className?: string;
  onClickHandler?: () => void;
}

export const Greeting = React.memo<GreetingProps>(
  ({ username, daysLeft, userCycleDuration, className, onClickHandler }) => {
    return (
      <Link
        className={clsx(
          'flex items-center py-2 pr-2 pl-6 gap-x-4 border border-khaki-5 rounded-full text-right text-xs font-lato',
          className,
        )}
        data-testid='account-link-desktop'
        href='/account'
        onClick={onClickHandler}
      >
        <div>
          <b className='block'>{username}</b>
          {daysLeft === 1 && 'Last day of cycle'}
          {daysLeft === userCycleDuration && 'First day of cycle'}
          {daysLeft > 1 && daysLeft < userCycleDuration && `${daysLeft} days left in cycle`}
        </div>
        <Avatar letters={getInitials(username)} variant='sm' />
      </Link>
    );
  },
);

Greeting.displayName = 'Greeting';
