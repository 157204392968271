import * as React from 'react';

import { ArrowSquareOut } from '@phosphor-icons/react';
import { format, formatDistanceStrict } from 'date-fns';
import Image from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { Button, Spinner } from '@/components/ui';
import { trpc } from '@/utils/trpc';

import { RouterOutput } from '../../../trpc/router';

interface SubscriptionOverviewProps {
  dbUser: RouterOutput['users']['byId']['data'];
}

export const SubscriptionOverview = React.memo<SubscriptionOverviewProps>(({ dbUser }) => {
  const router = useRouter();
  const { data: subscriptionData, isLoading: subscriptionDataIsLoading } = trpc.users.subscriptionGet.useQuery({
    id: dbUser.id,
  });

  const {
    mutate: createBillingPortalSession,
    isLoading: stripeCustomerDataIsLoading,
    isSuccess,
  } = trpc.subscriptions.sessions.createBillingPortalSession.useMutation({
    onSuccess: (data) => {
      if (data.url) router.replace(data.url);
    },
  });

  if (!dbUser.isSubscriber) {
    return (
      <div className='max-w-2xl mx-auto px-6 text-center'>
        <h2 className='font-lato text-2xl font-bold mb-1'>You do not have a subscription</h2>
        <p className='mb-4'>Sign up and get access to our premium features!</p>
        <Button onClick={() => router.push('/signup/subscribe')}>Subscribe</Button>
      </div>
    );
  }

  if (subscriptionDataIsLoading) {
    return (
      <div className='flex justify-center max-w-2xl mx-auto px-6 text-center'>
        <Spinner />
      </div>
    );
  }

  const subscription: any = Object.values(subscriptionData?.data.subscriptions || {})[0];
  const currentPeriodEndDate = subscription?.expires_date ? new Date(subscription?.expires_date) : new Date();
  const entitlement = Object.keys(subscriptionData?.data.entitlements || {})[0];
  const isAppStore = subscription?.store === 'app_store';
  const isNormal =
    subscription?.store === 'app_store' || subscription?.store === 'play_store' || subscription?.store === 'stripe';
  const isPlayStore = subscription?.store === 'play_store';
  const isStripe = subscription?.store === 'stripe';
  const isPromotional = subscription?.store === 'promotional';
  const isTrial = subscription?.period_type === 'trial';
  const renewalDate = format(currentPeriodEndDate, 'MMM do, yyyy');
  const timeTillRenewal = formatDistanceStrict(currentPeriodEndDate, new Date());
  const willRenew = !subscription?.unsubscribe_detected_at && !subscription?.billing_issues_detected_at;

  return (
    <div className='border border-khaki rounded-xl p-4'>
      <div className='flex flex-row'>
        <div className='flex flex-col justify-center mr-4'>
          <Image alt='subscription icon' height={104} src='/images/account/28-subscription-icon.png' width={104} />
        </div>
        <div className='flex flex-col justify-center'>
          <h2 className='font-lato text-slate font-bold'>{entitlement}</h2>
          <div className='justify-start'>
            {isTrial && willRenew && <div>Your trial will end in {timeTillRenewal}.</div>}
            {isPromotional && <div>Your promotional subscription will end in {timeTillRenewal}.</div>}
            {!isPromotional && willRenew ? (
              <div>
                <strong>Next payment:</strong> {renewalDate}
              </div>
            ) : isNormal ? (
              <div>
                Your Subscription is <strong>cancelled</strong> and will not auto renew.
                <br /> It will expire on {renewalDate}
              </div>
            ) : null}
            {isNormal && !isStripe && (
              <div>
                Manage your subscription in{' '}
                {isAppStore ? (
                  <>
                    the <br />
                    <NextLink href={subscriptionData?.data.managementURL || ''} target='_blank'>
                      App Store <ArrowSquareOut className='inline' />
                    </NextLink>
                  </>
                ) : isPlayStore ? (
                  <>
                    the <br />
                    <NextLink href={subscriptionData?.data.managementURL || ''} target='_blank'>
                      Google Play Store <ArrowSquareOut className='inline' />
                    </NextLink>
                  </>
                ) : (
                  'app.'
                )}
              </div>
            )}
            {isNormal && isStripe && (
              <button
                className='underline'
                disabled={stripeCustomerDataIsLoading || isSuccess}
                onClick={() => createBillingPortalSession()}
              >
                Manage Subscription
                {stripeCustomerDataIsLoading && <Spinner className='ml-2 inline' />}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

SubscriptionOverview.displayName = 'SubscriptionOverview';
