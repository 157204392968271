import * as React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Instructor } from '@prisma/client';
import { useRouter } from 'next/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { z } from 'zod';

import { ButtonLink } from '@/components/common';
import { ImageSelectField } from '@/components/images';
import { Button, Input, Textarea } from '@/components/ui';
import { useLeavePrevention } from '@/hooks/useLeavePrevention';
import { ERROR, WARN } from '@/utils/form';
import { trpc } from '@/utils/trpc';

import { RouterOutput } from '../../../trpc/router';

export type InstructorUpdateFormValues = Pick<Instructor, 'name' | 'photoId' | 'biography'> & {
  coverPhotoId?: string;
  yearsExp?: number;
  instagram?: string;
  twitter?: string;
  tikTok?: string;
};

const schema: z.ZodType<InstructorUpdateFormValues> = z.lazy(() =>
  z.object({
    name: z.string().min(1, { message: ERROR.REQUIRED }),
    photoId: z.string().min(1, { message: ERROR.REQUIRED }),
    coverPhotoId: z.string().optional(),
    biography: z.string().nullable(),
    yearsExp: z.number().optional(),
    instagram: z.string().optional(),
    twitter: z.string().optional(),
    tikTok: z.string().optional(),
  }),
);

export interface InstructorUpdateFormProps {
  instructor: RouterOutput['instructors']['byId'];
}

export const InstructorUpdateForm = React.memo<InstructorUpdateFormProps>(({ instructor }) => {
  const router = useRouter();
  const utils = trpc.useContext();

  const yearsExp = JSON.parse(JSON.stringify(instructor?.extra))?.yearsExp;
  const instagram = JSON.parse(JSON.stringify(instructor?.extra))?.instagram;
  const tikTok = JSON.parse(JSON.stringify(instructor?.extra))?.tikTok;
  const twitter = JSON.parse(JSON.stringify(instructor?.extra))?.twitter;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isDirty, isSubmitSuccessful },
  } = useForm<InstructorUpdateFormValues>({
    defaultValues: {
      name: instructor.name,
      photoId: instructor.photo?.id ?? undefined,
      coverPhotoId: instructor.coverPhoto?.imageId ?? undefined,
      biography: instructor.biography,
      yearsExp,
      instagram,
      twitter,
      tikTok,
    },
    resolver: zodResolver(schema),
  });

  const { mutate } = trpc.instructors.update.useMutation({
    onMutate: () => toast.loading('Saving...'),
    onError: ({ message }) => {
      toast.remove();
      toast.error(message);
    },
    onSuccess: async (data) => {
      await Promise.all([utils.instructors.byId.invalidate({ id: data.id }), utils.instructors.all.invalidate()]);
      toast.remove();
      toast.success(`Instructor ${data.name} updated`);
      router.push('/admin/instructors');
    },
  });

  useLeavePrevention(isDirty && !isSubmitting && !isSubmitSuccessful, WARN.UNSAVED_CHANGES);

  const onSubmit = React.useCallback<SubmitHandler<InstructorUpdateFormValues>>(
    ({ name, photoId, coverPhotoId, biography, yearsExp, instagram, tikTok, twitter }) => {
      const extra = { yearsExp, instagram, twitter, tikTok };

      return mutate({
        id: instructor.id,
        name,
        photoId,
        coverPhotoId,
        biography,
        extra,
      });
    },
    [instructor.id, mutate],
  );

  return (
    <form className='bg-khaki-1 rounded-lg p-8 overflow-hidden' onSubmit={handleSubmit(onSubmit)}>
      <Input errors={errors} label='Name' {...register('name')} />
      <ImageSelectField
        height={152}
        image={instructor.photo}
        label='Headshot'
        name='photoId'
        onImageSelect={(img) => setValue('photoId', img.id, { shouldDirty: true })}
        width={152}
      />
      <ImageSelectField
        height={152}
        image={instructor?.coverPhoto?.image}
        label='Cover Photo'
        name='coverPhotoId'
        onImageSelect={(img) => setValue('coverPhotoId', img.id, { shouldDirty: true })}
        width={152}
      />
      <Textarea errors={errors} label='Biography' {...register('biography')} />
      <Input
        errors={errors}
        label='Years of Experience'
        {...register('yearsExp', { valueAsNumber: true })}
        type='number'
      />
      <Input errors={errors} label='Instagram Handle' {...register('instagram')} type='string' />
      <Input errors={errors} label='TikTok Handle' {...register('tikTok')} type='string' />
      <Input errors={errors} label='Twitter Handle' {...register('twitter')} type='string' />
      <div className='flex gap-x-6 mt-2 -mx-8 -mb-8 p-8 py-5 rounded-bl-lg bg-khaki-2-3'>
        <Button
          disabled={isSubmitting || !isDirty}
          state={isSubmitting ? 'waiting' : undefined}
          type='submit'
          variant='primary'
        >
          Update
        </Button>
        {!instructor._count.workouts && (
          <ButtonLink href={`/admin/instructors/${instructor.id}/delete`} variant='minimal'>
            Delete
          </ButtonLink>
        )}
      </div>
    </form>
  );
});

InstructorUpdateForm.displayName = 'InstructorUpdateForm';
