var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"d58f247257c49de7878821340928a6b6152d7fbf"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  environment: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? 'production' : 'development' || 'development',
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 0.1,
  ignoreErrors: [
    'Unauthorized',
    'UNAUTHORIZED',
    'Insufficient privileges',
    'Insufficent privileges',
    'Invalid token provided',
    'Unsupported format for the Authorization header',
    'No partner found',
    'IGNORE ME',
  ],
  beforeSend(event, hint) {
    const error = hint.originalException;
    if (error && error.message) {
      if (error.message.match(/Invalid token provided/i)) {
        return null;
      }
      if (error.message.match(/The provided token expired/i)) {
        return null;
      }
      if (error.message.match(/Unauthorized/i)) {
        return null;
      }
      if (error.message.match(/UNAUTHORIZED/i)) {
        return null;
      }
      if (error.message.match(/Insufficient privileges/i)) {
        return null;
      }
      if (error.message.match(/Insufficent privileges/i)) {
        return null;
      }
      if (error.message.match(/Unsupported format for the Authorization header/i)) {
        return null;
      }
    }
    return event;
  },
});
