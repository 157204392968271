import * as React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Intensity, TargetArea, Workout } from '@prisma/client';
import { useRouter } from 'next/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { z } from 'zod';

import { ButtonLink, FormErrorMessage } from '@/components/common';
import { ExerciseGearSelectField } from '@/components/exerciseGear/ExerciseGearSelectField';
import { ImageSelectField } from '@/components/images';
import { InstructorSelectField } from '@/components/instructors';
import { PhasedaysSelectField } from '@/components/phases';
import { Button, Checkbox, Input, Radio, Textarea } from '@/components/ui';
import { VideoSelectField } from '@/components/videos';
import { useLeavePrevention } from '@/hooks/useLeavePrevention';
import { ERROR, WARN } from '@/utils/form';
import { capitalize } from '@/utils/string';
import { trpc } from '@/utils/trpc';

import { RouterOutput } from '../../../trpc/router';

type WorkoutUpdateFormValues = {
  title: string;
  content: string;
  why: string;
  intensity: string;
  targetArea: string;
  instructorId: string;
  imageId: string;
  videoId: string;
  published: boolean;
  previewVideoId?: string;
  removePreview: boolean;
};

const schema: z.ZodType<
  Omit<WorkoutUpdateFormValues, 'phasedayId'> & {
    phasedayIds: string[];
    exerciseGearIds?: string[];
  }
> = z.lazy(() =>
  z.object({
    title: z.string().min(1, { message: ERROR.REQUIRED }),
    content: z.string().min(1, { message: ERROR.REQUIRED }),
    why: z.string(),
    intensity: z.enum(['LOW', 'MODERATE', 'HIGH'], {
      required_error: ERROR.REQUIRED,
    }),
    targetArea: z.enum(['FULL_BODY', 'LEGS', 'BUTT', 'CORE', 'ARMS', 'STRETCH'], { required_error: ERROR.REQUIRED }),
    instructorId: z.string().min(1, { message: ERROR.REQUIRED }),
    phasedayIds: z.array(z.string()).min(1, { message: ERROR.REQUIRED }),
    exerciseGearIds: z.array(z.string()).optional(),
    imageId: z.string().min(1, { message: ERROR.REQUIRED }),
    videoId: z.string().min(1, { message: ERROR.REQUIRED }),
    previewVideoId: z.string().optional(),
    published: z.boolean(),
    isFeatured: z.boolean(),
    removePreview: z.boolean(),
  }),
);

export interface WorkoutUpdateFormProps {
  workout: RouterOutput['workouts']['byId']['data'];
}

export const WorkoutUpdateForm = React.memo<WorkoutUpdateFormProps>(({ workout }) => {
  const router = useRouter();
  const utils = trpc.useContext();

  const { data: phasedays } = trpc.phasedays.all.useQuery(undefined);

  // const { data: exerciseGear } = trpc.exerciseGear.all.useQuery({});

  const { mutate, isLoading } = trpc.workouts.update.useMutation({
    onMutate: () => toast.loading('Saving...'),
    onSuccess: async (data) => {
      await Promise.all([utils.workouts.all.invalidate(), utils.workouts.byId.invalidate({ id: data.id })]);
      toast.remove();
      toast.success(`Workout ${data.title} updated`);
      router.push(`/admin/workouts`);
    },
    onError: ({ message }) => {
      toast.remove();
      toast.error(message);
    },
  });

  const {
    register,
    reset,
    getValues,
    formState: { errors, isSubmitting, isDirty, isSubmitSuccessful },
    handleSubmit,
    setValue,
  } = useForm<
    Workout & {
      phasedayIds: string[];
      previewVideoId?: string;
      removePreview: boolean;
      exerciseGearIds?: string[];
    }
  >({
    defaultValues: {
      title: workout.title,
      content: workout.content,
      why: workout.why,
      intensity: workout.intensity,
      targetArea: workout.targetArea,
      instructorId: workout.instructorId || undefined,
      phasedayIds: workout.phasedays.map((p) => p.phaseday.id) || undefined,
      exerciseGearIds: workout.exerciseGear.map((e) => e.exerciseGear.id) || undefined,
      imageId: workout.image?.id ?? undefined,
      videoId: workout.video?.id ?? undefined,
      published: workout.published,
      isFeatured: workout.isFeatured,
      previewVideoId: workout.previewVideo?.videoId,
      removePreview: false,
    },
    resolver: zodResolver(schema),
  });

  useLeavePrevention(isDirty && !isSubmitting && !isSubmitSuccessful, WARN.UNSAVED_CHANGES);

  React.useEffect(() => {
    if (phasedays) {
      const values = getValues();
      reset(values);
    }
  }, [phasedays, reset, getValues]);

  const onSubmit = React.useCallback<
    SubmitHandler<
      Workout & {
        phasedayIds: string[];
        previewVideoId?: string;
        removePreview: boolean;
        exerciseGearIds?: string[];
      }
    >
  >(
    (data) => {
      mutate({
        id: workout.id,
        title: data.title,
        content: data.content,
        why: data.why,
        intensity: data.intensity,
        targetArea: data.targetArea,
        imageId: data.imageId,
        videoId: data.videoId,
        instructorId: data.instructorId,
        phasedays: data.phasedayIds,
        exerciseGear: data.exerciseGearIds,
        published: data.published,
        isFeatured: data.isFeatured,
        previewVideoId: data.previewVideoId,
        removePreview: data.removePreview,
      });
    },
    [mutate, workout.id],
  );

  //? I don't know why react-hook-form doesn't isn't tracking this the right way.
  const removedPreview = getValues('removePreview') === true;

  return (
    <form className='bg-khaki-1 rounded-lg p-8 overflow-hidden' onSubmit={handleSubmit(onSubmit)}>
      <Input errors={errors} label='Title' {...register('title')} />
      <Textarea errors={errors} label='Content' {...register('content')} />
      <Textarea errors={errors} label='Why We Recommend It' {...register('why')} />
      <VideoSelectField
        errors={errors}
        label='Video'
        name='videoId'
        onVideoSelect={(video) => video && setValue('videoId', video.id, { shouldDirty: true })}
        video={workout.video}
      />
      <VideoSelectField
        errors={errors}
        label='Preview Video'
        name='previewVideoId'
        onVideoSelect={(video) => video && setValue('previewVideoId', video.id, { shouldDirty: true })}
        onlyPreviews={true}
        removePreview={() =>
          setValue('removePreview', true, {
            shouldDirty: true,
            shouldTouch: true,
          })
        }
        video={getValues('removePreview') ? undefined : workout.previewVideo?.video}
      />
      <ImageSelectField
        errors={errors}
        height={202}
        image={workout.image}
        label='Image'
        name='imageId'
        onImageSelect={(img) => setValue('imageId', img.id, { shouldDirty: true })}
        width={360}
      />
      <InstructorSelectField
        errors={errors}
        instructor={workout.instructor}
        label='Instructor'
        name='instructorId'
        onInstructorSelect={(instructor) =>
          instructor && setValue('instructorId', instructor.id, { shouldDirty: true })
        }
      />
      <PhasedaysSelectField
        errors={errors}
        label='Phasedays'
        name='phasedayIds'
        onPhasedaysSelect={(phasedays) =>
          setValue(
            'phasedayIds',
            phasedays.map((p) => p.phaseday.id),
            { shouldDirty: true },
          )
        }
        phasedays={workout.phasedays}
      />
      <ExerciseGearSelectField
        errors={errors}
        exerciseGear={workout.exerciseGear.map((e) => ({
          exerciseGear: {
            ...e.exerciseGear,
            photo: e?.exerciseGear?.photo?.url ?? undefined,
          },
        }))}
        label='Exercise Gear'
        name='phasedayIds'
        onExerciseGearSelect={(exerciseGear) => {
          setValue(
            'exerciseGearIds',
            exerciseGear.map((eg) => eg.exerciseGear.id),
            { shouldDirty: true },
          );
        }}
      />
      <div>
        <label className='font-josefin font-bold block mb-2 tracking-tighter'>Intensity</label>
        <div className='flex gap-6 items-center'>
          {Object.keys(Intensity).map((value) => (
            <Radio key={value} label={capitalize(value.toLowerCase())} {...register('intensity')} value={value} />
          ))}
        </div>
        <FormErrorMessage errors={errors} name='intensity' />
      </div>
      <div>
        <label className='font-josefin font-bold block mb-2 tracking-tighter'>Target Area</label>
        <div className='flex gap-6 items-center'>
          {Object.keys(TargetArea).map((value) => (
            <Radio
              key={value}
              label={capitalize(value.replace(/_/g, ' ').toLowerCase())}
              {...register('targetArea')}
              value={value}
            />
          ))}
        </div>
        <FormErrorMessage errors={errors} name='targetArea' />
        <Checkbox className='mt-8' label='Published' {...register('published')} />
        <Checkbox className='mt-8' label='Featured' {...register('isFeatured')} />
      </div>
      <div className='flex gap-x-6 mt-2 -mx-8 -mb-8 p-8 py-5 rounded-bl-lg bg-khaki-2-3'>
        <Button
          disabled={(isSubmitting || !isDirty || isLoading) && !removedPreview}
          state={isSubmitting || isLoading ? 'waiting' : undefined}
          type='submit'
          variant='primary'
        >
          Update
        </Button>
        <ButtonLink href={`/admin/workouts/${workout.id}/delete`} variant='minimal'>
          Delete
        </ButtonLink>
      </div>
    </form>
  );
});

WorkoutUpdateForm.displayName = 'WorkoutUpdateForm';
